@tailwind base;
@tailwind components;
@tailwind utilities;


/* @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Nunito:wght@200;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;600;800&display=swap');

/* 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(180deg,rgba(92,77,66,.5) 0,rgba(92,77,66,.5)),url(/assets/img/bg/nathaniel-yeo-747NDboAWNY-unsplash.jpg); */
  background: linear-gradient(180deg,rgba(92,77,66,.5) 0,rgba(92,77,66,.5)),url('./assets/img/bg/nathaniel-yeo-747NDboAWNY-unsplash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}


* {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

div .text-formal > * {
  font-family: 'Nunito', sans-serif !important;
}

div .text-formal > table, tr, td {
  font-family: 'Nunito', sans-serif !important;
  vertical-align: top;
}

.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  z-index: 2;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.overlay {
  position: absolute;
  width: 500%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

@media (min-width: 120px) {
  #avatar-mahasiswa {
    height: 60px!important;
    width: 60px!important;
  }
  #avatar-dosen {
    height: 60px!important;
    width: 60px!important;
  }
}

@media (min-width: 640px) {
  #avatar-mahasiswa {
    height: 90px!important;
    width: 90px!important;
  }
  
  #avatar-dosen {
    height: 90px!important;
    width: 90px!important;
  }

}

@media (min-width: 1024px) {
  #avatar-mahasiswa {
    height: 120px!important;
    width: 120px!important;
  }
  #avatar-dosen {
    height: 120px!important;
    width: 120px!important;
  }
}

#avatar-profil {
  height: 120px!important;
  width: 120px!important;
}